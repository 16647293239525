import styled from "@emotion/styled";

import { t } from "~src/designSystem/theme";

import { HelpWidget } from ".";

interface IProps {
  children?: React.ReactNode;
}

export const HelpWidgetWrapper: React.FC<IProps> = ({ children }) => {
  return (
    <>
      {children}
      <HelpWidgetStyled />
    </>
  );
};

const HelpWidgetStyled = styled(HelpWidget)`
  position: fixed;

  bottom: ${t.space[8].toString()};
  right: ${t.space[8].toString()};
`;
