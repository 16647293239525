import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Card } from "~src/designSystem/atoms/Card";
import { Link } from "~src/designSystem/atoms/Link";
import { ListBoxItemButton } from "~src/designSystem/atoms/ListBoxItem/ListBoxItemButton";
import { Popover } from "~src/designSystem/atoms/Popover";
import { Text } from "~src/designSystem/atoms/Text";
import { BlueprintIcon } from "~src/designSystem/deprecated/BlueprintIcon";
import { t } from "~src/designSystem/theme";
import { PIPE_HELP_URL, SUPPORT_EMAIL, SUPPORT_EMAIL_TEMPLATE_HREF } from "~src/shared/constants";

import { getSupportPhoneNumber } from "../helpers/support";
import { ISegmentTrackEvent, useAnalytics } from "../thirdParties/segment";
import { ICountryCode } from "../types";

export interface IHelpWidgetProps {
  country?: ICountryCode;
  path: string;
  className?: string;
  showIntercom?: () => void;
}

export const HelpWidgetPresentation: React.FC<IHelpWidgetProps> = ({
  country,
  path,
  className,
  showIntercom,
}) => {
  const supportNumber = getSupportPhoneNumber(country);

  const { trackEvent } = useAnalytics();
  const trackHelpEvent = (event: ISegmentTrackEvent) => trackEvent(event, { path });

  return (
    <Popover
      placement="top right"
      onClick={() => trackHelpEvent(ISegmentTrackEvent.HelpWidgetButtonClicked)}
    >
      <ButtonWrapper className={className}>
        <Text text="titleSM" css={{ fontWeight: 700 }}>
          ?
        </Text>
      </ButtonWrapper>

      {({ closePopover }) => (
        <StyledCard isActive padding="0px">
          <Link
            onClick={() => trackHelpEvent(ISegmentTrackEvent.HelpWidgetViewHelpDocsClicked)}
            newTab
            href={PIPE_HELP_URL}
          >
            <StyledListItem
              label="View help docs"
              iconNode={<StyledIcon icon="book" />}
              kind="secondary"
            />
          </Link>

          <Divider />

          {showIntercom != null && (
            <StyledDescriptionListItem
              onClick={() => {
                showIntercom();
                closePopover();
                trackHelpEvent(ISegmentTrackEvent.HelpWidgetOpenIntercomClicked);
              }}
              label="Send us a message"
              iconNode={<StyledIcon icon="chat" />}
              kind="secondary"
            />
          )}

          <Link
            onClick={() => trackHelpEvent(ISegmentTrackEvent.HelpWidgetSendEmailClicked)}
            newTab
            href={SUPPORT_EMAIL_TEMPLATE_HREF}
          >
            <StyledDescriptionListItem
              label="Send us an email"
              description={SUPPORT_EMAIL}
              iconNode={<StyledIcon icon="inbox" />}
              kind="secondary"
            />
          </Link>

          <Link
            onClick={() => trackHelpEvent(ISegmentTrackEvent.HelpWidgetCallUsClicked)}
            href={`tel:${supportNumber}`}
          >
            <StyledDescriptionListItem
              label="Call us"
              description={supportNumber}
              iconNode={<StyledIcon icon="phone" />}
              kind="secondary"
            />
          </Link>
        </StyledCard>
      )}
    </Popover>
  );
};

const StyledCard = styled(Card)`
  overflow: hidden;
`;

const StyledIcon = styled(BlueprintIcon)`
  color: ${(props) => props.theme.components.HelpWidget.icon};

  & svg {
    display: block;
  }
`;

const StyledListItem = styled(ListBoxItemButton)`
  padding-right: ${t.space[4].toString()};
  border-radius: ${t.radii[0].toString()};

  background-color: ${(props) => props.theme.components.HelpWidget.background.default};
`;

const StyledDescriptionListItem = styled(StyledListItem)`
  --padding-size: ${t.space[2].toString()};

  padding-top: var(--padding-size);
  padding-bottom: var(--padding-size);
`;

const Divider = styled.hr`
  border-top: 1px solid ${(props) => props.theme.components.Divider.box};
`;

const ButtonWrapper = styled.button`
  height: 40px;
  width: 40px;
  display: grid;
  place-content: center;

  cursor: pointer;

  ${({ theme }) => {
    const buttonStyle = theme.components.ButtonAria.secondary;
    return css`
      background: ${buttonStyle.background.default};
      color: ${buttonStyle.color.default};
      border: 1px solid ${buttonStyle.border.default};
      transition: ${theme.transition};
      border-radius: ${t.radii.circle.toString()};

      &:hover {
        background: ${buttonStyle.background.hover};
        color: ${buttonStyle.color.hover};
        border: 1px solid ${buttonStyle.border.hover};
      }
      &:active {
        background: ${buttonStyle.background.active};
        color: ${buttonStyle.color.active};
        border: 1px solid ${buttonStyle.border.active};
      }
    `;
  }}
`;
