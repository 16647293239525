import { css, SerializedStyles, Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { PropsWithChildren } from "react";

import { PleaseGoUseFlexInstead } from "~src/designSystem/deprecated/Flexbox";

type IThemeBackgroundColor = "foreground" | "background";

export const TEXT_THEMES: {
  [key in IThemeBackgroundColor]: (theme: Theme) => SerializedStyles;
} = {
  foreground: (theme) => css`
    background-color: ${theme.components.Modal.foreground};
  `,
  background: (theme) => css`
    :not(:last-child) {
      border-bottom: 1px solid ${theme.components.Divider.default};
    }
    background-color: ${theme.components.Modal.background};
  `,
};

type IProps = {
  background: IThemeBackgroundColor;
} & React.ComponentProps<typeof PleaseGoUseFlexInstead>;

/**
 * Use this within a modal.
 */
export const ModalInner: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { children, ...rest } = props;
  return (
    <Wrapper pad {...rest}>
      <Body>{children}</Body>
    </Wrapper>
  );
};

const Body = styled.div`
  z-index: 11;
  position: relative;
  a {
    color: ${(props) => props.theme.components.Link.text.default};
    &:hover {
      color: ${(props) => props.theme.components.Link.text.hover};
    }
  }
`;

const Wrapper = styled(PleaseGoUseFlexInstead)<IProps>`
  ${(props) => TEXT_THEMES[props.background](props.theme)}
  h1 {
    color: ${({ theme }) => theme.components.Modal.title};
  }
  color: ${({ theme }) => theme.components.Modal.text};
`;
